
import { Component, Vue } from 'vue-property-decorator';
import VWrapper from '../../components/shared/VWrapper.vue';
import ImportForm from '../../components/shared/form/ImportForm.vue';
import { mixins } from 'vue-class-component';
import Editable from '@/mixins/Editable';
import Notification from '@/mixins/Notification';
import { namespace } from 'vuex-class';
import WorkflowApiService from '@/api/http/WorkflowApiService';

const workflow = namespace('workflow');

@Component({
  components: { ImportForm, VWrapper },
})
export default class ImportWorkflow extends mixins(Editable, Notification) {
  public $refs!: {
    form: InstanceType<typeof ImportForm> & { getData: () => any; validate: () => Promise<boolean | boolean[]> };
  };

  public save() {
    this.$refs.form.validate().then(async (res: boolean) => {
      if (res) {
        const api: WorkflowApiService = new WorkflowApiService();
        const data: FormData = new FormData();
        data.append('file', this.$refs.form.getData().file);

        this.$startLoading('workflow');
        await api.upload(data);
        this.$stopLoading('workflow');
      }
    });
  }
}
